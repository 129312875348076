
import { defineComponent, onMounted, ref, reactive } from "vue";
import { getOrganization } from "@/core/services/JwtService";
import { getWaystarFields, postWaystarFields } from "@/api/waystarfields.api";
import { setCurrentPageTitle } from "@/core/helpers/breadcrumb";

export interface Data {
  model: unknown;
}
export default defineComponent({
  name: "WaystarFormPage",
  components: {},
  props: ["claimId"],
  setup() {
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      model: {},
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      setCurrentPageTitle("Waystar Form Page");
      await getAll();
    });

    async function getAll() {
      data.model = await getWaystarFields("");
    }

    async function postForm() {
      const res = await postWaystarFields(data.model);
    }

    return {
      organizationId,
      data,
      postForm,
    };
  },
});
