import ApiService from "@/core/services/ApiService";

const url = "api/v1/WaystarFields";

export interface WaystarFieldsDto {
  items : WaystarItemDto[]
}

export interface WaystarItemDto {
  listGroup: number,
  required: boolean,
  fieldsName: string,
  fields : WaystarFieldDto[];
}
export interface WaystarFieldDto {
  field : string ,
  ubBox : string ,
  fieldName : string ,
  required : string ,
  description: string ,
  value : string ,
  sourceObject: string ,
  sourceProperty: string ,
  listGroup : string ,
  subListGroup : string ,
}



export async function getWaystarFields(waystarSearch: string) : Promise<WaystarFieldsDto> {
  const request = {
    formType: waystarSearch,
  };
  return await ApiService.post(
    "/api/v1/WaystarFields/search",
    request as never
  ).then((res) => {
    return res.data;
  });
}

export async function postWaystarFields(waystarFields) {
  return await ApiService.post(url, { WaystarFields: waystarFields } as never);
}
